import { Autocomplete, FormControl, TextField, Chip } from '@mui/material';
import { FormArray, FormControl as FormControlType, FormGroup } from 'react-reactive-form';
import { useEffect } from 'react';

import { Color } from '../../../interfaces/Color';
import { useState } from 'react';
import axios from 'axios';
import { GET_ALLERGIES } from '../../../constants/Endpoints';
import moment from 'moment';
interface AllergyOption {
  label: string;
  value: string;
}

// created wrapper component to use hooks to rerender fetched allergy options
const WrapperMultiselect = ({ defaultValue, onChange, disabled, isRequired, getColor, getError, handler, label, placeholder }: any) => {
  const [options, setOptions] = useState<AllergyOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<any>(moment());
  const [search, setSearch] = useState<string>('');

  const fetchAllergiesByName = async (keyword: string) => {
    try {
      setOptions([]);
      if (keyword.length > 2) {
        setLoading(true);
        const res: any = await axios.get(`${process.env.REACT_APP_GRX_BASE_URL}${GET_ALLERGIES}/${keyword}`);
        if (res.status === 200) {
          if (Array.isArray(res.data)) {
            let resOptions: AllergyOption[] = res.data.map((elem: any) => ({ label: elem.Allergy_Name, value: elem.Allergy_Name }));
            console.log(resOptions, "456")
            setOptions(resOptions);
          } else {
            setOptions([]);
          }
        }
        setLoading(false);
      }
    } catch (error: any) {
      setOptions([]);
      setLoading(false);
      console.log('Error', error);
    }
  };

  const searchDebounce = (searchFunction: () => void, timeInterval: number) => {
    if (moment().diff(moment(startTime)) > timeInterval) {
      searchFunction();
      setStartTime(moment());
    }
  };

  useEffect(() => {
    if (search) {
      searchDebounce(() => fetchAllergiesByName(search), 400);
    }
  }, [search]);

  return (
    <Autocomplete
      multiple
      fullWidth
      id="tags-filled"
      defaultValue={defaultValue}
      options={(options || []).map((option: AllergyOption) => option.label)}
      freeSolo
      renderTags={(value: readonly string[], getTagProps) => value.map((option: string, index: number) => <Chip label={option} {...getTagProps({ index })} />)}
      filterSelectedOptions
      onInputChange={(e: React.SyntheticEvent<Element, Event>, newValue: any) => {
        e.preventDefault();
        setSearch((prev) => newValue);
      }}
      onChange={(event: React.SyntheticEvent<Element, Event>, newValue: unknown) => {
        if (Array.isArray(newValue)) {
          const filteredValues = newValue.filter(
            (value) =>
              typeof value === 'string' &&
              !value.toLowerCase().includes('null') && // Exclude "null"
              value.trim() !== '' // Exclude empty strings
          );
          onChange(event, filteredValues);
        } else {
          onChange(event, newValue);
        }
      }}
      loading={loading}
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          padding: '6px'
        }
      }}
      renderInput={(params) => <TextField onMouseDownCapture={(e) => e.stopPropagation()} required={isRequired()} color={getColor()} error={getError()} {...handler()} {...params} label={label} placeholder={placeholder} />}
      disabled={disabled}
    />
  );
};

export const AllergyAutoCompleteInput = ({ handler, touched, hasError, meta }: FormArray | FormControlType | FormGroup) => {
  const inputElem = handler();
  const defaultOnChange = inputElem.onChange;

  // const onChange = (e: React.SyntheticEvent<Element, Event>, newValue: unknown) => {
  //   defaultOnChange(e);
  //   if (meta.onChange) {
  //     meta.onChange(newValue);
  //   }
  // };

  const onChange = (e: React.SyntheticEvent<Element, Event>, newValue: unknown) => {
    defaultOnChange(e);

    console.log(newValue, "Filtered Values");

    if (Array.isArray(newValue)) {
      // Filter out invalid values
      const validValues = newValue.filter(
        (value) =>
          typeof value === 'string' &&
          !value.toLowerCase().includes('null') && // Exclude any string containing "null" (case-insensitive)
          value.trim() !== '' // Exclude empty or whitespace-only strings
      );

      // Update meta with filtered values
      if (meta.onChange) {
        meta.onChange(validValues);
      }
    } else {
      // Pass the value directly if it's not an array
      if (meta.onChange) {
        meta.onChange(newValue);
      }
    }
  };




  const getColor = () => {
    if (touched) {
      if (hasError('required')) {
        return Color.ERROR;
      }
      return Color.SUCCESS;
    }
    return Color.PRIMARY;
  };

  const getError = () => {
    if (typeof meta.required != 'undefined' && meta.required === false) {
      return false;
    }

    if (touched) {
      if (hasError('required')) {
        return true;
      }
      return false;
    }

    return false;
  };

  const isRequired = () => {
    if (typeof meta.required != 'undefined') {
      return meta.required;
    }

    return true;
  };

  return (
    <FormControl className="form-input" sx={{ width: '100%' }}>
      <WrapperMultiselect defaultValue={meta?.selectedAllergyOptions} onChange={onChange} disabled={meta.disabled} isRequired={isRequired} getColor={getColor} getError={getError} handler={handler} label={meta.label} placeholder={meta.placeholder} />
    </FormControl>
  );
};
