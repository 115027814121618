import React, { useState, useEffect } from 'react';
import { Alert, Box, Button, Container, Grid, Stack, Typography, FormControl, TextField, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import TertiaryButton from '../../../../core/buttons/tertiary-button';
import { ChangeEvent } from 'react';
import { FieldControl, FieldGroup, Validators } from 'react-reactive-form';
import { RootState, useAppDispatch, useAppSelector } from '../../../../store';
import { patientRepresentativeForm, refillPreferenceForm, settingUpdatePatinetRepresentativeFormHandler, settingUpdateRefillPreferenceHandler, settingUpdateUserProfileHandler } from '../../../../services/pages/settings/personalLoginForm';
import { personalLoginForm, personalUserForm } from '../../../../services/pages/settings/personalLoginForm';
import { addNewShippingAddressForm } from '../../../../services/pages/popup-form/AddNewShippingAddressForm.service';
import EmailInputIcon from '../../../../core/forms/inputs/EmailInputIcon';
import { InputPhone } from '../../../../core/forms/inputs/InputPhone';
import { InputText } from '../../../../core/forms/inputs/InputText';
import Login from '../../../../assets/icons/login.svg';
import PasswordInputIconlogin from '../../../../core/forms/inputs/PasswordInputIconlogin';
import PhysicianDoctor from '../../../../constants/api';
import { User } from '../../../../models/User';
import cameraPic from '../../../../assets/icons/camera.svg';
import { profileActions } from '../../../../store/Actions';
import profilePic from '../../../../assets/icons/user.svg';
import { useNavigate } from 'react-router-dom';
import Doctor from '../../../../constants/grx-api';
import { InputSelect } from '../../../../core/forms/inputs/InputSelect';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormArray, FormControl as FormControlType, FormGroup } from 'react-reactive-form';
import { Color } from '../../../../interfaces/Color';
import moment from 'moment';
import { InputEmail } from '../../../../core/forms/InputEmail';
import { notificationOptions } from '../../../../services/components/selectOptions.service';
import { AllergyAutoCompleteInput } from '../../../../core/forms/inputs/AllergyAutoCompleteInput';
import Volate from '../../../../assets/icons/volate.svg';
import rxIcon from '../../../../assets/icons/rx_icon.svg';
import deleteIcon from '../../../../assets/icons/deleteIcon.svg';
import editIcon from '../../../../assets/icons/deleat_edit.svg';
import Home from '../../../../assets/icons/shipping_home.svg';
import { BootstrapDialog } from '../../../../core/tables/tableStyles';
import { AddNewCard } from '../../../../components/popup/AddNewCard';
import { AddNewAddress } from '../../../../components/popup/AddNewAddress';
import { EditShippingAddress } from '../../../../components/popup/EditShippingAddress';
import { genderOptions } from '../../../../services/components/selectOptions.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { AxiosResponse } from 'axios';
import { DELETE_PATIENT_CARD, GET_ALLERGIES, GET_PATIENT_CARDS, SET_DEFAULT_CARD, GET_PATIENT_SHIPPING_ADDRESS, PATIENT_PROFILE_IMAGE_CHANGE, LINK_EXISTING_USER_PHONE, LINK_EXISTING_USER_EMAIL, SEND_SMS_OTP, SEND_EMAIL_OTP } from '../../../../constants/Endpoints';
import Patient from '../../../../constants/grx-api';
import { toast } from 'react-toastify';
import { InputDateOfBirth } from '../../../../core/forms/inputs/InputDateOfBirth';
import { isLoggedIn } from '../../../../services/auth/auth.service';
import InputPassword from '../../../../core/forms/inputs/InputPassword';
import { LinkPhoneNumber } from '../../../../components/popup/LinkPhoneNumber';
import { LinkEmailAddress } from '../../../../components/popup/LinkEmailAddress';
import { sendEmailOtpHandler, sendSmsOtpHandler } from '../../../../services/auth/verifyAccount.service';
import { RadioInput } from '../../../../core/forms/inputs/RadioInput';
import SecondaryButton from '../../../../core/buttons/secondary-button';
import { InputDate } from '../../../../core/forms/inputDate';
import { formatMessage } from '../../../../constants/util';
import { debounce } from '@mui/material/utils';
import axios from 'axios';
import { forgotPasswordHandler, forgotPswForm } from '../../../../services/auth/forgotPassword.service';

const UserInfo = React.memo(() => {
  const user: User = useAppSelector((state: RootState) => state.profileReducer.user);
  const dispatch = useAppDispatch();
  const [cards, setCards] = useState<any>([]);
  const [shippingAddress, setShippingAdrress] = useState<any>([]);
  // const [allergyOptions, setAllergyOptions] = useState([]);
  const [data, setData] = useState({
    card: false,
    newAddress: false,
    editAddress: false,
    linkPhoneNumber: false,
    linkEmailAddress: false
  });

  const onSelectAllergies = (data: { value: string }[]) => {
    const value = data.map((item) => item).join();
    personalUserForm.patchValue({
      allergies: value
    });
  };

  // const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
  //   if (e?.target?.files?.length) {
  //     try {
  //       e.preventDefault();
  //       const formData = new FormData();
  //       formData.append('file', e.target.files[0]);
  //       const res: AxiosResponse = await Patient.post(PATIENT_PROFILE_IMAGE_CHANGE, formData, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'multipart/form-data'
  //         }
  //       });
  //       if (res.status === 201) {
  //         const newUser = { ...user, profilePicture: { ...user.profilePicture, ...res.data } };
  //         const duplicateUser: User = User.create({ ...newUser }, true) as User;
  //         dispatch(profileActions.setUserData({ user: duplicateUser as User }));
  //       }
  //     } catch (err: any) {
  //       if (err?.response?.data?.message) {
  //         toast(formatMessage(err?.response.data.message));
  //         return;
  //       }
  //     }
  //   }
  // };

  const settingResetPasswordHandler = async (e?: React.SyntheticEvent) => {
    await forgotPasswordHandler();
  };

  const settingPersonalInfoHandler = async (e?: React.SyntheticEvent) => {
    const res: any = await settingUpdateUserProfileHandler(e as React.SyntheticEvent, user);
    if (res?.status === 201) {
      dispatch(profileActions.setUserData({ user: User.create(res.data, true) as User }));
      // navigate('/home/settings');
    }
  };

  const handleUpdateRefillPreference = async (e?: React.SyntheticEvent) => {
    const res: any = await settingUpdateRefillPreferenceHandler(e as React.SyntheticEvent, user);
    if (res?.status === 201) {
      dispatch(profileActions.setUserData({ user: User.create(res.data, true) as User }));
      // navigate('/home/settings');
    }
  };

  const handleUpdatePatientRepresentative = async (e?: React.SyntheticEvent) => {
    const res: any = await settingUpdatePatinetRepresentativeFormHandler(e as React.SyntheticEvent, user);
    if (res?.status === 201) {
      dispatch(profileActions.setUserData({ user: User.create(res.data, true) as User }));
      // navigate('/home/settings');
    }
  };

  const handleNewCard = () => {
    setData((oldData) => ({
      ...oldData,
      card: true
    }));
  };

  const handleCardClose = (e?: string | React.SyntheticEvent) => {
    if (e === 'OK') {
      getCards();

      // As the user has added cards, so set the 'hasDefaultBillingCard' to true
      dispatch(profileActions.setUserData({ user: User.create({ ...user, hasDefaultBillingCard: true }, true) as User }));
    }
    setData((oldData) => ({
      ...oldData,
      card: false
    }));
  };

  const handlePopupClose = () => {
    setData((oldData) => ({
      ...oldData,
      card: false,
      newAddress: false,
      editAddress: false,
      linkPhoneNumber: false,
      linkEmailAddress: false
    }));
  };

  const handleNewAddress = () => {
    setData((oldData) => ({
      ...oldData,
      newAddress: true
    }));
  };

  const handleNewAddressClose = () => {
    setData((oldData) => ({
      ...oldData,
      newAddress: false
    }));
  };

  const handleEditAddress = () => {
    // addNewShippingAddressForm.reset();
    addNewShippingAddressForm.patchValue({
      street: shippingAddress.addr1,
      apt_suite: shippingAddress.addr2,
      city: shippingAddress.city,
      state: shippingAddress.state_cd,
      code: shippingAddress.zip,
      address: shippingAddress.addr1
    });
    setData((oldData) => ({
      ...oldData,
      editAddress: true
    }));
  };

  const handleEditAddressClose = (res?: string) => {
    setData((oldData) => ({
      ...oldData,
      editAddress: false
    }));
    if (res === 'OK') {
      getShippingAddress();
    }
  };

  const handleLinkPhoneNumber = async () => {
    const res = await sendSmsOtpHandler(user?.phoneNumber?.areaCode, user?.phoneNumber?.number);
    if (res) {
      setData((oldData) => ({
        ...oldData,
        linkPhoneNumber: true
      }));
    }
  };

  const handleLinkPhoneNumberClose = (e?: React.SyntheticEvent | boolean) => {
    if (e === true) {
      const duplicateUser: User = User.create({ ...user, isPhoneNumberVerified: true }, true) as User;
      dispatch(profileActions.setUserData({ user: duplicateUser as User }));
    }
    handlePopupClose();
  };

  const handleLinkEmailAddress = async () => {
    const res = await sendEmailOtpHandler(user?.email);
    if (res) {
      setData((oldData) => ({
        ...oldData,
        linkEmailAddress: true
      }));
    }
  };

  const handleLinkEmailAddressClose = (e?: React.SyntheticEvent | boolean) => {
    if (e === true) {
      const duplicateUser: User = User.create({ ...user, isEmailVerified: true }, true) as User;
      dispatch(profileActions.setUserData({ user: duplicateUser as User }));
    }
    handlePopupClose();
  };

  const handleSetDefaultCard = async (cardId: string) => {
    try {
      const res: AxiosResponse = await Patient.patch(`${SET_DEFAULT_CARD}/${cardId}`);
      if (res.status === 200) {
        getCards();
      }
    } catch (err: any) {
      if (err?.response?.data?.message) {
        toast(formatMessage(err?.response.data.message));
        return;
      }
    }
  };

  const getCards = async () => {
    try {
      const res: AxiosResponse = await Patient.get(`${GET_PATIENT_CARDS}/${user?.patientId}`);
      if (res.status === 200) {
        setCards([...res.data]);
      }
    } catch (err: any) {
      if (err?.response?.data?.message) {
        toast(formatMessage(err?.response.data.message));
        return;
      }
    }
  };

  const getShippingAddress = async () => {
    try {
      const res: AxiosResponse = await Patient.get(GET_PATIENT_SHIPPING_ADDRESS);
      if (res.status === 200) {
        setShippingAdrress(res?.data?.address);
      }
    } catch (err: any) {
      if (err?.response?.data?.message) {
        toast(formatMessage(err?.response.data.message));
        return;
      }
    }
  };

  const handleDeleteCard = async (cardId: any) => {

    try {
      if (cardId) {
        const res: AxiosResponse = await Patient.delete(`${DELETE_PATIENT_CARD}/${cardId}/${user?.patientId}`);
        if (res.status === 200) {
      
          let tempCards = cards.filter((card: any) => card.cardId !== cardId);
          if (tempCards.length === 0) {
            setData((oldData) => ({
              ...oldData,
              card: true
            }));
            // As the user has o number of cards, so set the 'hasDefaultBillingCard' to false
            // dispatch(profileActions.setUserData({ user: User.create({ ...user, hasDefaultBillingCard: false }, true) as User }));
          }
          setCards(tempCards);
  
        }
      }
    } catch (err: any) {
      if (err?.response?.data?.message) {
        toast(formatMessage(err?.response.data.message));
        return;
      }
    }
  };

  const onDateChange = (date: Date) => {
    console.log('DatepickerDate:::', date);
    personalUserForm.patchValue({
      dateOfBirth: date
    });
    // if (e && e.$d && e.$d !== null) {
    //   personalUserForm.patchValue({
    //     dateOfBirth: e.$d
    //   });
    // } else {
    //   personalUserForm.patchValue({
    //     dateOfBirth: ''
    //   });
    // }
  };

  useEffect(() => {
    getCards();
    getShippingAddress();
    // getAllergies();
    if (user && isLoggedIn()) {
      let formValues = {
        firstName: user?.firstName,
        middleName: user?.middleName,
        lastName: user?.lastName,
        dateOfBirth: user?.dateOfBirth,
        gender: user?.gender,
        phone: `${user?.phoneNumber?.areaCode}${user?.phoneNumber?.number}`.trim(),
        email: user?.email,
        notification_preference: user?.notificationPreference,
        allergies: user?.allergies.join(','),
        representativeFirstName: user?.patientRepresentative?.firstName,
        representativeLastName: user?.patientRepresentative?.lastName,
        representativeContact: `${user?.patientRepresentative?.phoneNumber?.areaCode}${user?.patientRepresentative?.phoneNumber?.number}`,
        automaticRefill: user?.isAutoRefill === 1,
        eyeDoctor: user?.eyeDoctor
      };
      // fill form values of personal user form, refill preference and patient representative form
      personalUserForm.patchValue(formValues);
      refillPreferenceForm.patchValue(formValues);
      patientRepresentativeForm.patchValue(formValues);
      forgotPswForm.patchValue({
        email: user.email
      });
    }
  }, [user]);

  // update patient representative form validation
  let isValidationUpdateInProgress = false;

  const updatePatientRepresentativeFormValidation = () => {
    if (isValidationUpdateInProgress) {
      return;
    }

    isValidationUpdateInProgress = true;

    const representativeFields = ['representativeFirstName', 'representativeLastName', 'representativeContact'];

    if (patientRepresentativeForm.get('representativeFirstName').value || patientRepresentativeForm.get('representativeLastName').value || patientRepresentativeForm.get('representativeContact').value.replace(/\D/g, '')) {
      representativeFields.forEach((field) => {
        const control = patientRepresentativeForm.get(field);
        control.setValidators([Validators.required]);
        control.updateValueAndValidity();
      });
    } else {
      representativeFields.forEach((field) => {
        const control = patientRepresentativeForm.get(field);
        control.setValidators([]);
        control.updateValueAndValidity();
      });
    }

    isValidationUpdateInProgress = false;
  };

  // Subscribe to changes in the form
  useEffect(() => {
    patientRepresentativeForm.valueChanges.subscribe(() => {
      updatePatientRepresentativeFormValidation();
    });

    return () => {
      patientRepresentativeForm.valueChanges.unsubscribe(() => null);
    };
  }, []);

  return (
    <>
      {data.card && (
        <BootstrapDialog open={data.card} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
          <AddNewCard handleClose={handleCardClose} />
        </BootstrapDialog>
      )}
      {data.newAddress && (
        <BootstrapDialog onClose={handleNewAddressClose} open={data.newAddress} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
          <AddNewAddress handleClose={handleNewAddressClose} />
        </BootstrapDialog>
      )}
      {data.editAddress && (
        <BootstrapDialog onClose={() => handleEditAddressClose()} open={data.editAddress} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
          <EditShippingAddress handleClose={(res?: string) => handleEditAddressClose(res)} />
        </BootstrapDialog>
      )}
      {data.linkPhoneNumber && (
        <BootstrapDialog onClose={() => handleLinkPhoneNumberClose()} open={data.linkPhoneNumber} PaperProps={{ style: { minHeight: 'auto', maxHeight: 'auto', minWidth: 'auto', maxWidth: 650 } }}>
          <LinkPhoneNumber handleClose={handleLinkPhoneNumberClose} areaCode={user?.phoneNumber?.areaCode} number={user?.phoneNumber?.number} />
        </BootstrapDialog>
      )}
      {data.linkEmailAddress && (
        <BootstrapDialog onClose={() => handleLinkEmailAddressClose()} open={data.linkEmailAddress} PaperProps={{ style: { minHeight: 'auto', maxHeight: 'auto', minWidth: 'auo', maxWidth: 650 } }}>
          <LinkEmailAddress handleClose={handleLinkEmailAddressClose} email={user?.email} />
        </BootstrapDialog>
      )}
      <Box>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center">
            <Grid item container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={{ xs: 0, sm: 0, md: 0 }} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className="account-heading">
                  <span>My Account</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={10} p={0}>
                <Grid className="setting_info" display="flex" direction="row" mb={4}>
                  {/* <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className="user_profile_alignment">
                    <Box>
                      {/* <Typography className="profile" style={{ padding: '28px 0px' }}>
                  <span>Profile</span>
                </Typography> */}
                  {/*<Box className="card-user-profile-img" style={user?.getSmallImage ? { background: `url('${user?.getSmallImage}')` } : {}}>
                        <label className="camera" htmlFor="upload-button">
                          <img src={cameraPic} alt="camera" width={38} height={38} />
                        </label>
                        <input type="file" id="upload-button" style={{ display: 'none' }} onChange={handleImageChange} />
                      </Box>
                    </Box>
                  </Grid> */}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box className="personal_details">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography className="heading">
                          <span className="profile_icon">
                            <img src={profilePic} alt="image" width={16} />
                          </span>
                          Personal
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FieldGroup
                          control={personalUserForm}
                          render={(get) => (
                            <form>
                              <Grid container spacing={2} className="personal personal_set_top">
                                <Grid container item spacing={2}>
                                  <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <FieldControl name="firstName" render={InputText} meta={{ label: 'First Name', name: 'firstName', value: 'firstName', helperText: 'First Name is required', placeholder: 'Please Enter First Name' }} />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <FieldControl name="middleName" render={InputText} meta={{ name: 'middleName', value: 'middleName', label: 'Middle Name', placeholder: 'Please Enter Middle Name', required: false }} />
                                  </Grid>
                                </Grid>
                                <Grid container item spacing={2}>
                                  <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <FieldControl name="lastName" render={InputText} meta={{ name: 'lastName', value: 'lastName', helperText: 'Last Name is required', label: 'Last Name', placeholder: 'Please Enter Last Name' }} />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <FieldControl
                                      name="dateOfBirth"
                                      render={InputDateOfBirth}
                                      meta={{
                                        name: 'dateOfBirth',
                                        value: personalUserForm.get('dateOfBirth').value,
                                        label: 'Date of Birth',
                                        placeholder: 'Please Enter Date of Birth',
                                        required: true,
                                        onChange: onDateChange,
                                        helperText: personalUserForm.get('dateOfBirth').errors?.required ? 'DOB is required' : personalUserForm.get('dateOfBirth').errors?.pattern ? 'Please enter valid DOB' : ''
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container item spacing={2}>
                                  <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <FieldControl
                                      name="gender"
                                      render={InputSelect}
                                      meta={{
                                        name: 'gender',
                                        value: 'gender',
                                        options: genderOptions,
                                        label: 'Gender',
                                        placeholder: 'Please Enter Gender'
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <FieldControl
                                      name="notification_preference"
                                      render={InputSelect}
                                      meta={{
                                        name: 'notification_preference',
                                        value: 'notification_preference',
                                        options: notificationOptions,
                                        label: 'Notification Preference',
                                        placeholder: 'Please Enter Notification Preference'
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container item spacing={2}>
                                  <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <FieldControl
                                      name="phone"
                                      render={InputPhone}
                                      meta={{
                                        name: 'phone',
                                        value: 'phone',
                                        helperText: 'Phone Number is required',
                                        label: 'Phone Number',
                                        placeholder: 'Please Enter Phone Number'
                                      }}
                                    />
                                    {/* {user?.isPhoneNumberVerified ? (
                                      <Alert severity="success" sx={{ mt: 1 }}>
                                        Phone is verified
                                      </Alert>
                                    ) : (
                                      <Alert
                                        severity="error"
                                        sx={{ mt: 1, color: '#d32f2f' }}
                                        icon={false}
                                        action={
                                          <Button variant="outlined" color="error" size="small" onClick={handleLinkPhoneNumber}>
                                            <strong>Verify</strong>
                                          </Button>
                                        }
                                      >
                                        For added security to your account, please verify your phone number.
                                      </Alert>
                                    )} */}
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <FieldControl
                                      name="email"
                                      render={InputEmail}
                                      meta={{
                                        id: 'user_email',
                                        name: 'email',
                                        value: 'email',
                                        helperText: 'Email is required.',
                                        label: 'Email',
                                        placeholder: 'Please Enter Email'
                                      }}
                                    />
                                    {user?.isEmailVerified ? (
                                      <Alert severity="success" sx={{ mt: 1 }}>
                                        Email is verified
                                      </Alert>
                                    ) : (
                                      <Alert
                                        severity="error"
                                        sx={{ mt: 1, color: '#d32f2f' }}
                                        icon={false}
                                        action={
                                          <Button variant="outlined" color="error" size="small" onClick={handleLinkEmailAddress}>
                                            <strong>Verify</strong>
                                          </Button>
                                        }
                                      >
                                        For added security to your account, please verify your Email.
                                      </Alert>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid container item spacing={2}>
                                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <FieldControl name="eyeDoctor" render={InputText} meta={{ name: 'eyeDoctor', value: 'eyeDoctor', label: 'Eye Doctor', placeholder: 'Eye Doctor', required: false, disabled: true }} />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <FieldControl
                                      name="allergies"
                                      render={AllergyAutoCompleteInput}
                                      meta={{
                                        value: 'allergies',
                                        name: 'allergies',
                                        label: 'Allergies',
                                        placeholder: 'Please Enter Allergies',
                                        selectedAllergyOptions: user?.allergies,
                                        onChange: onSelectAllergies
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </form>
                          )}
                        />
                      </Grid>
                      <Box sx={{ display: 'flex', mt: 1 }}>
                        <SecondaryButton label={'Update'} customStyles={{ width: '150px', height: '45px' }} onClick={(e) => settingPersonalInfoHandler(e)} />
                        <SecondaryButton label={'Reset Password'} customStyles={{ width: '250px', height: '45px', marginLeft: '16px' }} onClick={(e) => settingResetPasswordHandler(e)} />
                      </Box>
                    </Box>
                    <Box className="info_section">
                      <Grid container className="info_row" xs={12} sm={12} md={12} lg={12} spacing={3}>
                        <Grid item className="info_card info-justify" xs={12} sm={12} md={12} lg={6}>
                          <Typography className="heading">
                            <span className="profile_icon">
                              <img src={Volate} alt="Imprimis RX A Harrow Company" width={16} />
                            </span>
                            PAYMENT INFO
                          </Typography>
                          <Grid className="info">
                            <Typography variant="subtitle2" sx={{ mb: 1, mt: 2 }}>
                              In order to process your prescription, ImprimisRx requires a valid debit or credit card.
                            </Typography>

                            {cards.length < 1 && (
                              <Box className="handler_button">
                                <SecondaryButton label={'Add'} customStyles={{ width: '100px', height: '45px' }} onClick={handleNewCard} />
                              </Box>

                            )}

                            <Box className="card">
                              <Box className={cards?.length > 2 ? 'card-list scrollable-cards' : 'card-list'}>
                                <FormControl>
                                  {Array.isArray(cards) &&
                                    cards?.map((card, index) => (
                                      <>
                                        <Grid container sx={{ display: 'flex' }} xs={12} sm={12} md={12} lg={12} xl={12} mt={1}>
                                          <Grid item sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} ml={1.5}>
                                            <FormControlLabel value={card.cardId} control={<Radio checked={card.default} value={card.cardId} style={{display:"none"}}
                                              onChange={(e) => handleSetDefaultCard(e.target.value)} />} label={`${card.type} - xx${card.lastFourDigits}`} />
                                          </Grid>
                                          <Grid item sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <SecondaryButton label={'Delete And Add New Card'} customStyles={{ fontSize: '1rem', padding: 0, height: '45px', width: '250px', borderWidth: '1px' }} onClick={() => handleDeleteCard(card.cardId)} />
                                          </Grid>

                                          {/* <Stack onClick={() => handleDeleteCard(card.cardId)}>
                                          <img src={deleteIcon} alt="Imprimis RX A Harrow Company" width={16} />
                                        </Stack> */}
                                        </Grid>
                                      </>
                                    ))}
                                </FormControl>
                              </Box>
                            </Box>
                          </Grid>

                        </Grid>

                        <Grid item className="info_card" xs={12} sm={12} md={12} lg={6}>
                          <Typography className="heading">
                            <span className="profile_icon">
                              <img src={Home} alt="Imprimis RX A Harrow Company" width={16} />
                            </span>
                            SHIPPING INFO
                          </Typography>
                          <Grid className="info">
                            <Box className="address" sx={{ ml: '0 !important' }}>
                              <Typography component="h2" sx={{ mt: 2, mb: 1 }}>
                                Shipping Addresses
                              </Typography>
                              {shippingAddress && Object.keys(shippingAddress).length > 0 && (
                                <FormControl>
                                  <Stack direction="column" alignItems="flex-start" spacing={1}>
                                    <Stack>
                                      <Typography variant="subtitle1">{`${shippingAddress.addr1 || ''} ${shippingAddress.addr2 || ''} ${shippingAddress.addr3 || ''}`.trim()}</Typography>
                                      <Typography variant="subtitle1">{`${shippingAddress.city || ''} ${shippingAddress.state_cd || ''} ${shippingAddress.country_cd || ''} ${shippingAddress.zip}`.trim()}</Typography>
                                      <Typography variant="subtitle1">{`${shippingAddress.country_cd || ''} ${shippingAddress.zip || ''}`.trim()}</Typography>
                                    </Stack>
                                  </Stack>
                                </FormControl>
                              )}
                            </Box>
                          </Grid>
                          <Box className="handler_button">
                            {shippingAddress && Object.keys(shippingAddress).length > 0 ? (
                              <SecondaryButton label={'Update'} customStyles={{ width: '150px', height: '45px' }} onClick={handleEditAddress} />
                            ) : (
                              <SecondaryButton label={'Add New Address'} customStyles={{ width: '200px', height: '45px' }} onClick={handleNewAddress} />
                            )}
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container className="info_row" xs={12} sm={12} md={12} lg={12} spacing={2}>
                        {/* <Grid item className="info_card" xs={12} sm={12} md={12} lg={6}>
                          <Typography className="heading">
                            <span className="profile_icon">
                              <img src={rxIcon} alt="Imprimis RX A Harrow Company" width={16} height={16} />
                            </span>
                            REFILL PREFERENCE
                          </Typography>
                          <Grid className="info">
                            <Typography variant="subtitle2" sx={{ mb: 2, mt: 1 }}>
                              In order to ensure you have the medications you need, please consider enrolling in our automatic refill program.
                            </Typography>
                            <Box className="refill_preference">
                              <Typography component="h2">Automatic Refill Preferences </Typography>{' '}
                              <FieldGroup
                                control={refillPreferenceForm}
                                render={(get) => (
                                  <form>
                                    <Grid container item xs={12} sm={12} md={12} lg={12} className="allergies choose_allergies" sx={{ p: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                      <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FieldControl
                                          name="automaticRefill"
                                          render={RadioInput}
                                          meta={{
                                            name: 'automaticRefill',
                                            defaultValue: refillPreferenceForm.value.automaticRefill,
                                            options: [
                                              { label: 'Yes', value: 'true' },
                                              { label: 'No', value: 'false' }
                                            ]
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </form>
                                )}
                              />
                            </Box>
                          </Grid>
                          <Box className="handler_button">
                            <SecondaryButton label={'Update'} customStyles={{ width: '150px', height: '45px' }} onClick={handleUpdateRefillPreference} />
                          </Box>
                        </Grid> */}

                        <Grid item className="info_card" xs={12} sm={12} md={12} lg={6}>
                          <Typography className="heading">
                            <span className="profile_icon">
                              <img src={profilePic} alt="Imprimis RX A Harrow Company" width={16} />
                            </span>
                            MY TRUSTED CONTACT
                          </Typography>
                          <Grid className="info">
                            <Box className="patient_representatives">
                              <Typography variant="subtitle2" sx={{ mb: 2, mt: 2 }}>
                                By providing the information below, I give permission to ImprimisRx to communicate with my designated contact regarding my medical history.
                              </Typography>
                              <FieldGroup
                                control={patientRepresentativeForm}
                                render={(get) => (
                                  <form>
                                    <Grid container xs={12} sm={12} md={12} lg={12} spacing={2}>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FieldControl
                                          name="representativeFirstName"
                                          render={InputText}
                                          meta={{
                                            name: 'representativeFirstName',
                                            value: 'representativeFirstName',
                                            label: 'Patient Representative First Name',
                                            placeholder: 'Representative First Name',
                                            helperText: 'Representaive First Name is required',
                                            required: !!patientRepresentativeForm?.get('representativeFirstName')?.value || !!patientRepresentativeForm?.get('representativeLastName')?.value || !!patientRepresentativeForm?.get('representativeContact')?.value?.replace(/\D/g, '')
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FieldControl
                                          name="representativeLastName"
                                          render={InputText}
                                          meta={{
                                            name: 'representativeLastName',
                                            value: 'representativeLastName',
                                            label: 'Patient Representative Last Name',
                                            placeholder: 'Representative Last Name',
                                            helperText: 'Representaive Last Name is required',
                                            required: !!patientRepresentativeForm?.get('representativeFirstName')?.value || !!patientRepresentativeForm?.get('representativeLastName')?.value || !!patientRepresentativeForm?.get('representativeContact')?.value?.replace(/\D/g, '')
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FieldControl
                                          name="representativeContact"
                                          render={InputPhone}
                                          meta={{
                                            name: 'representativeContact',
                                            value: 'representativeContact',
                                            label: 'Patient Representative Contact',
                                            placeholder: 'Representative Contact',
                                            helperText: 'Representaive First Name is required',
                                            required: !!patientRepresentativeForm?.get('representativeFirstName')?.value || !!patientRepresentativeForm?.get('representativeLastName')?.value || !!patientRepresentativeForm?.get('representativeContact')?.value?.replace(/\D/g, '')
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </form>
                                )}
                              />
                            </Box>
                          </Grid>
                          <Box className="handler_button">
                            <SecondaryButton label={'Update'} customStyles={{ width: '150px', height: '45px' }} onClick={handleUpdatePatientRepresentative} />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </>
  );
});

export default UserInfo;
