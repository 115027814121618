import { AppBar, Box, Container, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { TabPanel, tabProps } from '../../../components/tabs';

import ChatFloatingIcon from '../../../components/chat-floating-icon';
import Logo from '../../../assets/icons/imprimisrx_logo.svg';
import GuestCheckOutForm from '../../../pages/signup/guest-checkout-form';
import React, { useEffect } from 'react';
// import StaffForm from "../../../pages/signup/staff-form";
import { useLocation, useNavigate } from 'react-router-dom';
import { PatientSignUpOneForm } from '../../../services/auth/register.service';
import callUsIcon from '../../../assets/icons/mobile.svg';

export default function GuestCheckout() {
  const router = useNavigate();
  const location = useLocation();

  const gotoLogin = () => {
    router('/');
  };

  // loiad the form data if found in the local storage

  return (
    <Stack component="main" className="default-layout signup-page">
      <Container maxWidth="xl">
        <Box className="head" sx={{ mb: 3 }}>
          <Box className="logo">
            <img src={Logo} alt="" width={150} onClick={gotoLogin} />
          </Box>
          <a href="tel:8582642082" className="call-us-link">
            <Stack direction="row" alignItems="center">
              <img src={callUsIcon} alt="Imprimis RX A Harrow Company" width={20} />
              <Typography>{`(858) 264-2082`}</Typography>
            </Stack>
            <Typography className="call-us-text">Text us with any questions</Typography>
          </a>
        </Box>
      </Container>

      <Container maxWidth="lg" className='sign-up-page'>
        <Box className="main-box">
          <Box sx={{ bgcolor: 'background.paper' }}>
            <GuestCheckOutForm />
          </Box>
        </Box>
      </Container>

      <ChatFloatingIcon />
    </Stack>
  );
}
