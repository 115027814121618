import { Box, Container, Stack } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';

import { AxiosResponse } from 'axios';
import Chat from '../../../assets/icons/chat.svg';
import Dialog from '@mui/material/Dialog';
import Patient from '../../../constants/grx-api';
import { GET_DELIVERED_ORDERS } from '../../../constants/Endpoints';
import Header from '../../../components/header/header';
import Prescribe from '../../../assets/icons/prescribe_icon.svg';
import { PrescriptionHistoryTable } from '../../../components/tables/PrescriptionHistoryTable';
import { PrescriptionInterface } from '../../../interfaces/prescription';
import SearchBar from '../../../components/search-bar';
import { User } from '../../../models/User';
import { dashboardActions, prescriptionActions } from '../../../store/Actions';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../../../services/auth/auth.service';
import Footer from '../../../components/footer/footer';
import { formatMessage } from '../../../constants/util';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export default function PrescriptionPage() {
  const router = useNavigate();
  const dispatch = useAppDispatch();
  const user: User = useAppSelector((state) => state.profileReducer.user);
  const orders: any = useAppSelector((state) => state.dashboardReducer.shippedOrders);
  const [orderList, setOrderList] = useState(orders);
  const [isLoaded, setIsLoaded] = useState(true);

  const [search, setSearch] = useState('');

  const setValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    setOrderList([])

  }, [])
  // orders

  const getDeliveredOrders = async (offset = 0, count = 10) => {
    try {
      const res: AxiosResponse = await Patient.get(GET_DELIVERED_ORDERS, {
        params: { offset, count }
      });
      if (res.status === 200 && Array.isArray(res.data) && res.data.length > 0) {
        dispatch(dashboardActions.setShippedOrders({ data: res.data }));
        setOrderList(res.data);
      }
    } catch (err: any) {
      if (err?.response?.data?.message) {
        toast(formatMessage(err?.response.data.message));
        return;
      }
    }
  };

  useEffect(() => {
    if (user) {
      if (isLoggedIn() === true) {
        // onload api calls
        getDeliveredOrders();
      }
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (!isLoggedIn()) {
      router('/');
    } else {
      setIsLoaded(true);
    }
  }, [router]);

  return isLoaded ? (
    <>
      <Stack component="main" className="default-layout">
        <Header />
        <Box>
          <Container maxWidth="xl">
            <Box component="main" className="patient-page">
              <Box className="main-content-wrapper-full">
                <Box className="main-box prescriptions_main">
                  <Container maxWidth="xl">
                    <Box className="heading_top">Past Prescriptions</Box>
                    <>
                      <SearchBar value={search} setValue={setValue} />

                      <Box className="recent-order-table-layout">
                        {orderList && (
                          <PrescriptionHistoryTable
                            getDeliveredOrders={getDeliveredOrders}
                            data={orderList?.filter((item: PrescriptionInterface) => {
                              return (search.length > 0 && (item?.order?.invoiceNumber + ' ' + item?.doctor?.firstName + ' ' + item.doctor?.lastName + ' ' + item?.prescriptions[0]?.name + ' ' + item.Medication).toLowerCase().includes(search.toLowerCase())) || search.length === 0;
                            })}
                          />
                        )}
                      </Box>
                    </>
                  </Container>
                </Box>
              </Box>
            </Box>
          </Container>
          <Box className="chat-floating-icon">
            <img src={Chat} alt="logo" height={65} width={65} />
          </Box>
          {/* <Box className="Prescribe-icon" onClick={handlePrescribeDetail}>
            <img src={Prescribe} alt="logo" height={100} width={180} />
          </Box> */}
        </Box>
      </Stack>
      <Footer />
    </>
  ) : (
    <></>
  );
}
