// import { FormArray, FormControl as FormControlType, FormGroup } from 'react-reactive-form';
// import { FormControl, TextField, Autocomplete } from '@mui/material';
// import moment from 'moment';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs from 'dayjs';
// import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';
// // import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// import { Color } from '../../../interfaces/Color';

// export const InputDateOfBirth = ({ handler, touched, hasError, meta }: FormArray | FormControlType | FormGroup) => {
//   const getColor = () => {
//     if (touched) {
//       if (hasError('required') || hasError('pattern')) {
//         return Color.ERROR;
//       }
//       return Color.SUCCESS;
//     }
//     return Color.PRIMARY;
//   };

//   const getError = () => {
//     if (typeof meta.required != 'undefined' && meta.required === false) {
//       return false;
//     }
//     if (touched) {
//       if (hasError('required')) {
//         return true;
//       }
//       return false;
//     }
//     return false;
//   };

//   const isRequired = () => {
//     if (typeof meta.required != 'undefined') {
//       return meta.required;
//     }
//     return true;
//   };

//   // Get the local timezone from the user's browser
//   // const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

//   dayjs.extend(utc);
//   dayjs.extend(timezone);
//   // dayjs.tz.setDefault('America/New_York');

//   // console.log('DATE:::', meta.value);
//   // console.log('DATE:::', dayjs(meta.value), localTimezone);
//   return (
//     <FormControl className="form-input" sx={{ width: '100%' }}>
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <DatePicker
//           value={meta.value ? dayjs.utc(meta.value) : null}
//           onChange={meta.onChange}
//           className="centered-text"
//           closeOnSelect={true}
//           disableFuture={true}
//           format={'MM-DD-YYYY'}
//           disabled={meta?.disabled ? meta?.disabled : false}
//           slotProps={{
//             textField: {
//               helperText: getError() && meta.helperText,
//               error: getError(),
//               required: isRequired(),
//               color: getColor(),
//               label: meta.label
//             }
//           }}
//         />
//       </LocalizationProvider>
//     </FormControl>
//   );
// };

import { FormArray, FormControl as FormControlType, FormGroup } from 'react-reactive-form';
import { FormControl, TextField, InputAdornment, Box } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from 'react-input-mask';

import { Color } from '../../../interfaces/Color';

const CalanderContainer = (props: { className?: string; children: any }) => {
  return (
    <Box className={props?.className} sx={{ backgroundColor: '#fffff' }}>
      <div style={{ position: 'relative', backgroundColor: '#fffff !important', opacity: 'none' }}>{props.children}</div>
    </Box>
  );
};

export const InputDateOfBirth = ({ handler, touched, hasError, meta }: FormArray | FormControlType | FormGroup) => {
  const getColor = () => {
    if (touched) {
      if (hasError('required') || hasError('pattern')) {
        return Color.ERROR;
      }
      return Color.SUCCESS;
    }
    return Color.PRIMARY;
  };

  const getError = () => {
    if (typeof meta.required != 'undefined' && meta.required === false) {
      return false;
    }
    if (touched) {
      if (hasError('required')) {
        return true;
      }
      return false;
    }
    return false;
  };

  const isRequired = () => {
    if (typeof meta.required != 'undefined') {
      return meta.required;
    }
    return true;
  };

  // Get the local timezone from the user's browser
  // const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  dayjs.extend(utc);
  dayjs.extend(timezone);
  // dayjs.tz.setDefault('America/New_York');

  function parseDateUTC(dateString: string | Date) {
    console.log('DS:::', dateString);
    if (typeof dateString === 'string') {
      var parts = dateString.split('-');
      var year = parseInt(parts[0]);
      var month = parseInt(parts[1]); // Months are zero-indexed
      var day = parseInt(parts[2]);

      // Create a new Date object with the provided year, month, and day in UTC
      const dateObj: any = dayjs()
        .set('year', year)
        .set('month', month - 1)
        .set('date', day);

      return new Date(dateObj);
    }
    return dateString;
  }

  const defaultDate = meta.value ? parseDateUTC(meta.value) : null;

  // console.log('Default DATE:::', defaultDate);
  // console.log('Default DATE:::', meta.value);
  return (
    <FormControl className="form-input" sx={{ width: '100%' }}>
      <DatePicker
        selected={meta.value ? parseDateUTC(meta.value) : null}
        onChange={meta.onChange}
        className="centered-text"
        closeOnScroll={true}
        // locale="en-US"
        dateFormat="MM-dd-yyyy"
        placeholderText={meta.placeholder}
        maxDate={new Date()}
        calendarContainer={CalanderContainer}
        showMonthDropdown
        dropdownMode="select"
        showYearDropdown
        customInput={
          <InputMask mask={'99-99-9999'} maskChar=" " value={meta.value} disabled={false} onChange={(e: any) => meta.onChange(e.target.value)}>
            {(inputProps: any) => (
              <TextField
                {...inputProps}
                helperText={getError() && meta.helperText}
                error={getError()}
                required={isRequired()}
                color={getColor()}
                label={meta.label}
                InputProps={{
                  ...inputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarMonthIcon />
                    </InputAdornment>
                  )
                }}
                InputLabelProps={{ required: true }}
                inputProps={{
                  ...inputProps.inputProps,
                  autoComplete: 'new-password'
                }}
                fullWidth
              />
            )}
          </InputMask>
        }
        wrapperClassName="date-picker-wrapper form-input" // Add wrapper class if needed
        popperPlacement="bottom-end" // Customize popper placement if needed
      />
    </FormControl>
  );
};
