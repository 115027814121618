import React, { useState } from 'react';
import { Box, FormControlLabel, Grid, Stack, Typography, FormControl, TextField, Alert, Button } from '@mui/material';
import { FieldControl, FieldGroup } from 'react-reactive-form';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { cityOptions, notificationOptions, stateOptions } from '../../services/components/selectOptions.service';
import { PatientSignUpTwoForm, PatientSignUpTwoHandler } from '../../services/auth/register.service';
import { useAppDispatch, useAppSelector } from '../../store';

import { AxiosResponse } from 'axios';
import { CheckInput } from '../../core/forms/inputs/CheckInput';
import { GET_SPECIALTIES } from '../../constants/Endpoints';
import { GoogleAutoCompleteInput } from '../../core/forms/inputs/GoogleAutoCompleteInput';
import { InputAddress } from '../../core/forms/inputs/InputAddress';
import { InputEmail } from '../../core/forms/InputEmail';
import InputPassword from '../../core/forms/inputs/InputPassword';
import { InputPhone } from '../../core/forms/inputs/InputPhone';
import { InputSelect } from '../../core/forms/inputs/InputSelect';
import { InputText } from '../../core/forms/inputs/InputText';
import Patient from '../../constants/grx-api';
import PrimaryButton from '../../core/buttons/primary-button';
import TertiaryButton from '../../core/buttons/tertiary-button';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { User } from '../../models/User';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormArray, FormControl as FormControlType, FormGroup } from 'react-reactive-form';
import { Color } from '../../interfaces/Color';
import moment from 'moment';
import { RadioInput } from '../../core/forms/inputs/RadioInput';
import { AllergyAutoCompleteInput } from '../../core/forms/inputs/AllergyAutoCompleteInput';
import { InputTextCC } from '../../core/forms/InputTextCC';
import { InputExpiryDate } from '../../core/forms/InputExpiryDate';
import { InputCvv } from '../../core/forms/InputCvv';
import { InputZipCode } from '../../core/forms/inputs/InputZipCode';
import { GET_ALLERGIES } from '../../constants/Endpoints';
import dayjs from 'dayjs';
import { genderOptions } from '../../services/components/selectOptions.service';
import { profileActions } from '../../store/Actions';
import PatientWithoutToken from '../../constants/grx-api-tokenless';
import { InputDateOfBirth } from '../../core/forms/inputs/InputDateOfBirth';
import { isLoggedIn } from '../../services/auth/auth.service';
import { InputDate } from '../../core/forms/inputDate';
import { formatMessage } from '../../constants/util';

export default function Index(props: any) {
  const router = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [birthDate, setBirthDate] = useState<any>('');
  const [allergyOptions, setAllergyOptions] = useState([]);
  const [patientData, setPatientdata] = useState<any>(props?.patientData);
  const isLogin = useAppSelector((state) => state.profileReducer.isLogin);

  const getAllergies = async () => {
    try {
      const res: AxiosResponse = await PatientWithoutToken.get(GET_ALLERGIES);

      setAllergyOptions(
        res.data.map((item: any) => {
          return { label: item?.Allergy_Name, value: item?.Allergy_Name };
        })
      );
    } catch (err: unknown) {
      console.log('something went wrong');
    }
  };

  const patientFormSubmit = async () => {
    const newUserLogged: any = await PatientSignUpTwoHandler(router);
    if (Object.keys(newUserLogged).length) {
      patientData.found = false;
      dispatch(profileActions.setUserData({ user: newUserLogged as User }));
    }
  };

  // const handleAutoCompleteChangeBilling = ({ address1, locality, short_name, postcode, country }: { address1: string; locality: string; short_name: string; postcode: string; country: string }) => {
  //   PatientSignUpTwoForm.patchValue({
  //     card_street: address1,
  //     card_city: locality,
  //     card_state: short_name,
  //     card_zip_code: postcode.split('-')[0],
  //     card_country: country
  //   });
  // };

  const handleAutoCompleteChangeShipping = ({ address1, locality, short_name, postcode, country }: { address1: string; locality: string; short_name: string; postcode: string; country: string }) => {
    PatientSignUpTwoForm.patchValue({
      street: address1,
      city: locality,
      state: short_name,
      zip_code: postcode.split('-')[0],
      country: country
    });
  };

  const onSelectAllergies = (data: { value: string }[]) => {
    const value = data.map((item) => item).join();
    PatientSignUpTwoForm.patchValue({
      allergies: value
    });
  };

  const loadFormData = async () => {
    PatientSignUpTwoForm.reset();
    let personalInfo = {
      firstName: patientData?.firstName,
      middleName: patientData?.middleName,
      lastName: patientData?.lastName,
      gender: patientData?.gender,
      dateOfBirth: patientData?.dateOfBirth,
      phone: (patientData?.areaCode + patientData?.phone).replace(/\D/g, ''),
      email: patientData?.email,
      password: patientData?.password,
      notification_preference: patientData?.notification_preference,
      isAllergies: patientData?.allergies !== '' ? 'true' : 'false',
      allergies: patientData?.allergies,
      representativeFirstName: patientData?.representativeFirstName,
      representativeLastName: patientData?.representativeLastName,
      representativeContact: patientData?.representativeContact,
      eyeDoctor: patientData?.eyeDoctor || '',
      // automaticRefill: patientData?.automaticRefill,
      updateConsent: patientData?.updateConsent || true,
      promotionConsent: patientData?.promotionConsent || true
    };

    // let billingInfo = {
    //   cardNumber: '',
    //   expiry: '',
    //   cvv: '',
    //   cardholderName: '',
    //   card_street: patientData?.billingAddress?.address1,
    //   card_apt_suite: patientData?.billingAddress?.address2,
    //   card_zip_code: patientData?.billingAddress?.zipCode,
    //   card_city: patientData?.billingAddress?.city,
    //   card_state: patientData?.billingAddress?.state
    // };
    let shippingInfo = {
      street: patientData?.shippingAddress?.address1,
      apt_suite: patientData?.shippingAddress?.address2,
      city: patientData?.shippingAddress?.city,
      state: patientData?.shippingAddress?.state,
      zip_code: patientData?.shippingAddress?.zipCode
    };
    // let consentInfo = {
    //   consent_one: patientData.consent_one,
    //   consent_two: patientData.consent_two
    // };

    const formData = {
      ...personalInfo,
      // ...billingInfo,
      ...shippingInfo
      // ...consentInfo
    };

    PatientSignUpTwoForm.patchValue({ ...formData });
  };

  useEffect(() => {
    if (!isLogin) {
      loadFormData();
    }
  }, [patientData]);

  const form = ({ get, invalid }: any) => (
    <form>
      <Grid container>
        <Grid item className="about" xs={12} lg={4} md={4} xl={4}>
          About You
        </Grid>
        {patientData?.found && (
          <Grid item className="about" xs={12} lg={8} md={8} xl={8}>
            <Alert icon={false}>
              <strong> We have located a prescription in our system for you. Please confirm the details below.</strong>
            </Alert>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={4} mb={2}>
          <Typography className="info">Personal Info</Typography>
          <span className="info-small">Please provide your personal info</span>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="firstName" render={InputText} meta={{ id: 'f_name', disabled: true, name: 'firstName', value: 'firstName', helperText: 'Patient First Name is Required', label: 'First Name', placeholder: 'Please Enter Patient First Name' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="middleName" render={InputText} meta={{ disabled: true, name: 'middleName', value: 'middleName', label: 'Middle Name', placeholder: 'Please Enter Patient Middle Name', required: false }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="lastName" render={InputText} meta={{ disabled: true, name: 'lastName', value: 'lastName', helperText: 'Patient Last Name is Required', label: 'Last Name', placeholder: 'Please Enter Patient Last Name' }} />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="gender" render={InputSelect} meta={{ disabled: true, name: 'gender', value: 'gender', options: genderOptions, label: 'Gender', placeholder: 'Please Enter Gender' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl
                name="dateOfBirth"
                render={InputDateOfBirth}
                meta={{
                  name: 'dateOfBirth',
                  value: get('dateOfBirth').value ? get('dateOfBirth').value : '',
                  label: 'Date of Birth',
                  placeholder: 'Please Enter Date of Birth',
                  required: true,
                  helperText: PatientSignUpTwoForm.get('dateOfBirth').errors?.required ? 'DOB is required' : PatientSignUpTwoForm.get('dateOfBirth').errors?.pattern ? 'Please enter valid DOB' : '',
                  disabled: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="phone" render={InputPhone} meta={{ disabled: true, name: 'phone', value: 'phone', helperText: 'Phone Number is Required', label: 'Phone Number', placeholder: 'Please Enter Phone Number' }} />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="notification_preference" render={InputSelect} meta={{ disabled: true, name: 'notification_preference', value: 'notification_preference', options: notificationOptions, label: 'Notification Preference', placeholder: 'Please Enter Notification Preference' }} />
            </Grid>

            <Grid container item xs={12} sm={12} md={12} lg={12} className="allergies choose_allergies">
              <Grid item>
                <Typography component="p" sx={{ marginRight: '10px' }}>
                  Patient allergies? <span>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <FieldControl
                  name="isAllergies"
                  render={RadioInput}
                  meta={{
                    disabled: true,
                    name: 'isAllergies',
                    defaultValue: get('isAllergies').value,
                    options: [
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' }
                    ]
                  }}
                />
              </Grid>
            </Grid>
            {get('isAllergies') && get('isAllergies').value === 'true' && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} pt={0}>
                  <FieldControl
                    name="allergies"
                    render={AllergyAutoCompleteInput}
                    meta={{
                      disabled: true,
                      value: 'allergies',
                      name: 'allergies',
                      label: 'Allergies',
                      placeholder: 'Please Enter Allergies',
                      options: allergyOptions,
                      // selectedAllergyOptions: patientData?.allergies !== '' ? patientData?.allergies?.split(',') : [],
                      selectedAllergyOptions: typeof patientData?.allergies === 'string'
                      ? patientData.allergies.split(',').filter((item: string) => item.trim() !== '')
                      : [],
                      onChange: onSelectAllergies
                    }}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 1, mb: 0 }}>
              <Typography variant="subtitle2">By providing the information below, I give permission to ImprimisRx to communicate with my designated contact regarding my medical history.</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 0 }}>
              <FieldControl name="representativeFirstName" render={InputText} meta={{ disabled: true, name: 'representativeFirstName', value: 'representativeFirstName', label: 'Patient Representative First Name', placeholder: 'Representative First Name', required: false }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="representativeLastName" render={InputText} meta={{ disabled: true, name: 'representativeLastName', value: 'representativeLastName', label: 'Patient Representative Last Name', placeholder: 'Representative Last Name', required: false }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="representativeContact" render={InputPhone} meta={{ disabled: true, name: 'representativeContact', value: 'representativeContact', label: 'Patient Representative Contact', placeholder: 'Representative Contact', required: false }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="eyeDoctor" render={InputText} meta={{ disabled: true, name: 'eyeDoctor', value: 'eyeDoctor', label: 'Eye Doctor', placeholder: 'Eye Doctor', required: false }} />
            </Grid>

            {/* <Grid container item xs={12} sm={12} md={12} lg={12} className="allergies choose_allergies">
              <Grid item>
                <Typography component="p" sx={{ marginRight: '10px' }}>
                  Automatic Refill Preferences{' '}
                </Typography>{' '}
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <FieldControl
                  name="automaticRefill"
                  render={RadioInput}
                  meta={{
                    disabled: true,
                    name: 'automaticRefill',
                    defaultValue: get('automaticRefill').value,
                    options: [
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' }
                    ]
                  }}
                />
              </Grid>
            </Grid> */}

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="email" render={InputEmail} meta={{ disabled: true, id: 'signup_step_2_email', name: 'email', value: 'email', helperText: 'Email is Required.', label: 'Email', placeholder: 'Please Enter Email' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="password" render={InputPassword} meta={{ id: 'signup_step_2_password', name: 'password', value: 'password', helperText: 'Password is Required', label: 'Password', placeholder: 'Please Enter Password' }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box className="divider"></Box>

      {/* <Grid container>
        <Grid item xs={12} lg={4} mb={2}>
          <Typography className="info">Billing Info</Typography>
          <span className="info-small">Please provide your billing information</span>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FieldControl name="cardNumber" render={InputTextCC} meta={{ name: 'cardNumber', value: 'cardNumber', label: 'Card Number', placeholder: 'Please Enter Card Number' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="expiry" render={InputExpiryDate} meta={{ name: 'expiry', value: 'expiry', label: 'Expiry Date', placeholder: 'Please Enter Expiry Date' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="cvv" render={InputCvv} meta={{ name: 'cvv', value: 'cvv', label: 'CVC/CVV', placeholder: 'Please Enter CVC/CVV' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FieldControl name="cardholderName" render={InputText} meta={{ name: 'cardholderName', value: 'cardholderName', label: 'Cardholder Name', placeholder: 'Please Enter Cardholder Name' }} />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} className="google-auto-complete-container" style={{ zIndex: 9999 }}>
              <GoogleAutoCompleteInput name="card_street" uniqueKey={'doctor-auto-complete-billing'} handleAutoCompleteChange={handleAutoCompleteChangeBilling} required={true} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="card_apt_suite" render={InputAddress} meta={{ name: 'card_apt_suite', label: 'Apt./Suite', placeholder: 'Please Enter Apt./Suite', required: false }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="card_city" render={InputAddress} meta={{ name: 'card_city', options: cityOptions, label: 'City', placeholder: 'Please Enter City', required: true }} />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="card_state" render={InputAddress} meta={{ name: 'state', options: stateOptions, label: 'State', placeholder: 'Please Enter State', required: true }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="card_zip_code" render={InputAddress} meta={{ name: 'card_code', helperText: 'Postal Code is required', label: 'Postal Code', placeholder: 'Please Enter Postal Code', required: true }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box className="divider"></Box> */}

      <Grid container>
        <Grid item xs={12} lg={4} mb={2}>
          <Typography className="info">Shipping Address</Typography>
          <span className="info-small">Please provide your shipping address</span>
        </Grid>

        <Grid container item xs={12} lg={8} spacing={2}>
          {/* <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControlLabel className="check-input-with-label" sx={{ margin: 1, marginLeft: 0 }} control={<FieldControl name="billing_shipping_same" render={CheckInput} />} label="Check if Billing Address is the same as Shipping Address" />
          </Grid> */}
          <Grid container item spacing={2}>
            {/* {get('billing_shipping_same') && get('billing_shipping_same')?.value === false && (
              <> */}
            <Grid item xs={12} sm={12} md={12} lg={12} className="google-auto-complete-container" style={{ zIndex: 9999 }}>
              <GoogleAutoCompleteInput uniqueKey={'doctor-auto-complete-shipping'} handleAutoCompleteChange={handleAutoCompleteChangeShipping} required={true} />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="apt_suite" render={InputAddress} meta={{ name: 'apt_suite', label: 'Apt./Suite', placeholder: 'Please Enter Apt./Suite', required: false }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="city" render={InputAddress} meta={{ name: 'city', options: cityOptions, label: 'City', placeholder: 'Please Enter City', helperText: 'City is Required', required: true }} />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="state" render={InputAddress} meta={{ name: 'state', options: stateOptions, label: 'State', helperText: 'State is Required', placeholder: 'Please Enter State', required: true }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="zip_code" render={InputAddress} meta={{ name: 'code', helperText: 'Postal Code is Required', label: 'Postal Code', placeholder: 'Please Enter Postal Code', required: true }} />
            </Grid>
            {/* </>
            )} */}
          </Grid>
        </Grid>
      </Grid>

      <Box className="divider"></Box>

      <Grid container>
        <Grid item xs={0} lg={4}>
          {/* <Typography className="info">Patient Representative</Typography> */}
          {/* <span className="info-small">Please provide your shipping address</span> */}
        </Grid>

        <Grid container item xs={12} lg={8} spacing={2}>
          <Grid container item spacing={2}></Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack direction={'row'} gap={2} mt={2} mb={1}>
              <FormControlLabel className="check-input-with-label" sx={{ margin: 0 }} control={<FieldControl name="updateConsent" render={CheckInput} />} label="I consent to receive updates from ImprimisRx via email, text, and phone." />
            </Stack>
            <Stack direction={'row'} mb={2}>
              <FormControlLabel className="check-input-with-label" sx={{ margin: 0 }} control={<FieldControl name="promotionConsent" render={CheckInput} />} label="I consent to receive occasional product and promotional updates from ImprimisRx via email, text, and phone." />
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid item xs={0} lg={4} mb={2}></Grid>
        <Grid container item xs={12} lg={8} spacing={2}>
          <Grid container item display="flex" justifyContent="space-between" mt={3} sx={{ width: '100%' }}>
            <Grid item xs={12} sm={12} md={5} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Box className="mandatory">
                <span>*</span> This field is mandatory
              </Box>
            </Grid>
            <Grid container item mt={1} xs={12} sm={12} md={6} lg={6} xl={6} p={0} sx={{ display: 'flex', direction: 'column', justifyContent: 'flex-end' }} spacing={1}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Box>
                  {/* <NavLink to="/"> */}
                  <TertiaryButton label={'Back'} onClick={() => router('/signup-step-one')} />
                  {/* </NavLink> */}
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Box>
                  <PrimaryButton label={'Register'} onClick={patientFormSubmit} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Box>
      <FieldGroup control={PatientSignUpTwoForm} render={form} />
    </Box>
  );
}
