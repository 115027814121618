import React, { useState } from 'react';
import { Box, FormControlLabel, Grid, Stack, Typography, FormControl, TextField, Avatar, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FieldControl, FieldGroup } from 'react-reactive-form';
import { NavLink, useNavigate } from 'react-router-dom';
import { cityOptions, notificationOptions, stateOptions } from '../../services/components/selectOptions.service';
import { PatientSignUpOneForm, PatientSignUpOneHandler } from '../../services/auth/register.service';
import { useAppDispatch, useAppSelector } from '../../store';

import { AxiosResponse } from 'axios';
import { CheckInput } from '../../core/forms/inputs/CheckInput';
import { GoogleAutoCompleteInput } from '../../core/forms/inputs/GoogleAutoCompleteInput';
import { InputAddress } from '../../core/forms/inputs/InputAddress';
import { InputEmail } from '../../core/forms/InputEmail';
import InputPassword from '../../core/forms/inputs/InputPassword';
import { InputPhone } from '../../core/forms/inputs/InputPhone';
import { InputSelect } from '../../core/forms/inputs/InputSelect';
import { InputText } from '../../core/forms/inputs/InputText';
import Patient from '../../constants/grx-api';
import PrimaryButton from '../../core/buttons/primary-button';
import TertiaryButton from '../../core/buttons/tertiary-button';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { User } from '../../models/User';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormArray, FormControl as FormControlType, FormGroup, Validators } from 'react-reactive-form';
import { Color } from '../../interfaces/Color';
import moment from 'moment';
import { RadioInput } from '../../core/forms/inputs/RadioInput';
import { AllergyAutoCompleteInput } from '../../core/forms/inputs/AllergyAutoCompleteInput';
import { InputTextCC } from '../../core/forms/InputTextCC';
import { InputExpiryDate } from '../../core/forms/InputExpiryDate';
import { InputCvv } from '../../core/forms/InputCvv';
import { InputZipCode } from '../../core/forms/inputs/InputZipCode';
import { GET_ALLERGIES } from '../../constants/Endpoints';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { genderOptions } from '../../services/components/selectOptions.service';
import { profileActions } from '../../store/Actions';
import PatientWithoutToken from '../../constants/grx-api-tokenless';
import { InputDate } from '../../core/forms/inputDate';
import { InputDateOfBirth } from '../../core/forms/inputs/InputDateOfBirth';
import SujaImg from '../../assets/suja.png';
import axios from 'axios';
import { debounce } from '@mui/material/utils';
interface CustomTooltipProps extends TooltipProps {
  open?: boolean;
}

const CustomWidthTooltip = styled(({ className, ...props }: CustomTooltipProps) => <Tooltip {...props} classes={{ popper: className }} open={true} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500
  }
});

const Index = React.memo(() => {
  const router = useNavigate();
  const [allergyOptions, setAllergyOptions] = useState<any>([]);

  const patientFormSubmit = async () => {
    await PatientSignUpOneHandler(router);
  };

  const onDateChange = (date: Date) => {
    console.log('Date Picker Date', moment.utc(date));
    PatientSignUpOneForm.patchValue({
      dateOfBirth: date
    });
    // if (e && e.$d && e.$d !== null) {
    //   PatientSignUpOneForm.patchValue({
    //     dateOfBirth: e.$d
    //   });
    // } else {
    //   PatientSignUpOneForm.patchValue({
    //     dateOfBirth: ''
    //   });
    // }
  };

  const onSelectAllergies = (data: { value: string }[]) => {
    const value = data.map((item) => item).join();
    PatientSignUpOneForm.patchValue({
      allergies: value
    });
  };

  // update form validation
  let isValidationUpdateInProgress = false;

  const updateValidation = () => {
    if (isValidationUpdateInProgress) {
      return;
    }

    isValidationUpdateInProgress = true;

    // update allergy validation
    if (PatientSignUpOneForm.get('isAllergies').value === 'true') {
      const control = PatientSignUpOneForm.get('allergies');
      control.setValidators([Validators.required]);
      control.updateValueAndValidity();
    } else {
      const control = PatientSignUpOneForm.get('allergies');
      control.setValidators([]);
      control.updateValueAndValidity();
    }

    // update representative fields validations
    const representativeFields = ['representativeFirstName', 'representativeLastName', 'representativeContact'];
    let representativeContact = PatientSignUpOneForm.get('representativeContact').value ? PatientSignUpOneForm.get('representativeContact').value.replace(/\D/g, '') : '';
    if (PatientSignUpOneForm.get('representativeFirstName').value || PatientSignUpOneForm.get('representativeLastName').value || representativeContact) {
      representativeFields.forEach((field) => {
        const control = PatientSignUpOneForm.get(field);
        control.setValidators([Validators.required]);
        control.updateValueAndValidity();
      });
    } else {
      representativeFields.forEach((field) => {
        const control = PatientSignUpOneForm.get(field);
        control.setValidators([]);
        control.updateValueAndValidity();
      });
    }

    isValidationUpdateInProgress = false;
  };

  useEffect(() => {
    PatientSignUpOneForm.valueChanges.subscribe(() => {
      updateValidation();
    });
    return () => {
      PatientSignUpOneForm.valueChanges.unsubscribe(() => null);
    };
  }, []);

  const form = ({ get, invalid }: any) => (
    <form>
      <Stack className="avtar-container">
        <Box className="avtar-container-warp">
          <Avatar sx={{ width: 56, height: 56 }} alt="Remy Sharp" src={SujaImg} />
          <Box sx={{ width: '100%' }}>
            <Typography className="dr-name">Dr. Suja Alum, PharmD, M.S., M.B.A.</Typography>
            <Typography className="avtar-message">
              Hello, I’m Suja Alum, the <span>pharmacist-in-charge</span> at ImprimisRx. In order to begin processing your prescription, please provide the information below. If you have any questions, please do not hesitate to contact our Customer Service team by using the <strong>chat</strong>{' '}
              feature at the bottom right of this page, or Text us at{' '}
              <a href="tel:8582642082">
                <strong>858-264-2082</strong>
              </a>
              {/* , or email us at{' '}
              <a href="mailto:info@imprimisrx.com">
                <strong>info@imprimisrx.com</strong>
              </a> */}
              .
            </Typography>
          </Box>
        </Box>
      </Stack>
      <Stack className="about">About You</Stack>
      <Grid container>
        <Grid item xs={12} lg={4} mb={2}>
          <Typography className="info">Patient Info</Typography>
          <span className="info-small">Please provide Patient info</span>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="firstName" render={InputText} meta={{ id: 'signup1_first_name', name: 'firstName', value: 'firstName', helperText: 'Patient First Name is Required', label: 'First Name', placeholder: 'Please Enter Patient First Name', autoComplete: false }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="middleName" render={InputText} meta={{ id: 'signup1_middle_name', name: 'middleName', value: 'middleName', label: 'Middle Name', placeholder: 'Please Enter Patient First Name', required: false, autoComplete: false }} />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="lastName" render={InputText} meta={{ id: 'signup1_last_name', name: 'lastName', value: 'lastName', helperText: 'Patient Last Name is Required', label: 'Last Name', placeholder: 'Please Enter Patient Last Name', autoComplete: false }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="gender" render={InputSelect} meta={{ name: 'gender', value: 'gender', options: genderOptions, label: 'Gender', placeholder: 'Please Enter Gender' }} />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl
                name="dateOfBirth"
                render={InputDateOfBirth}
                meta={{
                  name: 'dateOfBirth',
                  value: get('dateOfBirth').value ? get('dateOfBirth').value : '',
                  label: 'Date of Birth',
                  placeholder: 'Please Enter Date of Birth',
                  required: true,
                  onChange: onDateChange,
                  helperText: PatientSignUpOneForm.get('dateOfBirth').errors?.required ? 'DOB is Required' : PatientSignUpOneForm.get('dateOfBirth').errors?.pattern ? 'Please enter valid DOB' : ''
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="phone" render={InputPhone} meta={{ name: 'phone', value: 'phone', helperText: 'Phone Number is Required', label: 'Phone Number', placeholder: 'Please Enter Phone Number', autoComplete: false }} />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="email" render={InputEmail} meta={{ id: 'signup_step_1_email', name: 'email', value: 'email', helperText: 'Email is Required.', label: 'Email', placeholder: 'Please Enter Email', autoComplete: false }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="password" render={InputPassword} meta={{ id: 'signup_step_1_password', name: 'password', value: 'password', helperText: 'Password is Required', label: 'Password', placeholder: 'Please Enter Password', autoComplete: false }} />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="notification_preference" render={InputSelect} meta={{ name: 'notification_preference', value: 'notification_preference', options: notificationOptions, label: 'Notification Preference', placeholder: 'Please Enter Notification Preference' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FieldControl name="shippingZipCode" render={InputText} meta={{ name: 'shippingZipCode', value: 'shippingZipCode', helperText: 'Shipping Zip Code is Required', label: 'Shipping Zip', placeholder: 'Please Enter Shipping Zip Code', autoComplete: false }} />
            </Grid>

            <Grid container item xs={12} sm={12} md={12} lg={12} className="allergies choose_allergies">
              <Grid item>
                <Typography component="p" sx={{ marginRight: '10px' }}>
                  Patient Allergies? <span>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <FieldControl
                  name="isAllergies"
                  render={RadioInput}
                  meta={{
                    name: 'isAllergies',
                    defaultValue: get('isAllergies').value,
                    options: [
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' }
                    ]
                  }}
                />
              </Grid>
            </Grid>
            {get('isAllergies') && get('isAllergies').value === 'true' && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} pt={0}>
                  <FieldControl
                    name="allergies"
                    render={AllergyAutoCompleteInput}
                    meta={{
                      value: 'allergies',
                      name: 'allergies',
                      label: 'Allergies',
                      placeholder: 'Please Enter Allergies',
                      options: allergyOptions,
                      onChange: onSelectAllergies,
                      selectedAllergyOptions: get('allergies').value !== '' ? get('allergies').value?.split(',') : [],
                      required: true
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 1, mb: 0 }}>
              <Typography variant="subtitle2">By providing the information below, I give permission to ImprimisRx to communicate with my designated contact regarding my medical history.</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mt: 0 }}>
              <FieldControl
                name="representativeFirstName"
                render={InputText}
                meta={{
                  id: 'representativeFirstName_id',
                  name: 'representativeFirstName',
                  value: 'representativeFirstName',
                  label: 'Patient Representative First Name',
                  placeholder: 'Representative First Name',
                  autoComplete: false,
                  required: !!PatientSignUpOneForm?.get('representativeFirstName')?.value || !!PatientSignUpOneForm?.get('representativeLastName')?.value || !!PatientSignUpOneForm?.get('representativeContact')?.value?.replace(/\D/g, '')
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FieldControl
                name="representativeLastName"
                render={InputText}
                meta={{
                  id: 'representativeLastName_id',
                  name: 'representativeLastName',
                  value: 'representativeLastName',
                  label: 'Patient Representative Last Name',
                  placeholder: 'Representative Last Name',
                  autoComplete: false,
                  required: !!PatientSignUpOneForm?.get('representativeFirstName')?.value || !!PatientSignUpOneForm?.get('representativeLastName')?.value || !!PatientSignUpOneForm?.get('representativeContact')?.value?.replace(/\D/g, '')
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FieldControl
                name="representativeContact"
                render={InputPhone}
                meta={{
                  id: 'representativeContact_id',
                  name: 'representativeContact',
                  value: 'representativeContact',
                  label: 'Patient Representative Contact Number',
                  placeholder: 'Representative Contact',
                  autoComplete: false,
                  required: !!PatientSignUpOneForm?.get('representativeFirstName')?.value || !!PatientSignUpOneForm?.get('representativeLastName')?.value || !!PatientSignUpOneForm?.get('representativeContact')?.value?.replace(/\D/g, '')
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FieldControl name="eyeDoctor" render={InputText} meta={{ id: 'eyeDoctor_id', name: 'eyeDoctor', value: 'eyeDoctor', label: 'Eye Doctor', placeholder: 'Eye Doctor', required: false, autoComplete: false }} />
            </Grid>

            {/* <Grid container item xs={12} sm={12} md={12} lg={12} className="allergies choose_allergies">
              <Grid item>
                <Typography component="p" sx={{ marginRight: '10px' }}>
                  Automatic Refill Preferences{' '}
                </Typography>{' '}
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <FieldControl
                  name="automaticRefill"
                  render={RadioInput}
                  meta={{
                    name: 'automaticRefill',
                    defaultValue: get('automaticRefill').value,
                    options: [
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' }
                    ]
                  }}
                />
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid item xs={0} lg={4} mb={2}></Grid>
        <Grid container item xs={12} lg={8} spacing={2}>
          <Grid container item display="flex" justifyContent="space-between" mt={3} sx={{ width: '100%' }}>
            <Grid item xs={12} sm={12} md={5} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Box className="mandatory">
                <span>*</span> This field is mandatory
              </Box>
            </Grid>
            <Grid container item mt={1} xs={12} sm={12} md={6} lg={6} xl={6} p={0} sx={{ display: 'flex', direction: 'column', justifyContent: 'flex-end' }} spacing={1}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Box>
                  <NavLink to="/">
                    <TertiaryButton label={'Cancel'} />
                  </NavLink>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Box>
                  <PrimaryButton label={'Next'} onClick={patientFormSubmit} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Box sx={{ height: 'auto' }}>
      <FieldGroup control={PatientSignUpOneForm} render={form} />
    </Box>
  );
});

export default Index;
